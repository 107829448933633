var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"relative flex flex-wrap items-center justify-between mx-auto bg-white shadow-lg"},[_c('div',{staticClass:"flex flex-wrap items-center justify-between w-full px-2 py-2 mx-auto lg:px-10"},[_c('div',{staticClass:"absolute top-0 left-0 w-full h-full bg-white depth-fix"}),_c('div',{staticClass:"flex items-center mr-2 text-blue-600 lg:mr-6"},[_c('router-link',{staticClass:"flex-grow h-full",attrs:{"to":{
                    name: 'New Home',
                    params: { region: _vm.getRegion() }
                }}},[_c('img',{staticClass:"nav-logo",attrs:{"src":require("../assets/img/gddc-text.svg"),"alt":"Gabba Doggy Daycare"}})])],1),_c('div',{staticClass:"block lg:hidden"},[_c('button',{staticClass:"flex bg-pink-500 rounded focus:outline-pink",class:{ opened: _vm.show },attrs:{"id":"menu-button","aria-label":"Main Menu"},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('svg',{attrs:{"width":"38","height":"38","viewBox":"0 0 100 100"}},[_c('path',{staticClass:"line line1",attrs:{"d":"M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"}}),_c('path',{staticClass:"line line2",attrs:{"d":"M 20,50 H 80"}}),_c('path',{staticClass:"line line3",attrs:{"d":"M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"}})])])]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"absolute left-0 flex-grow block w-full px-4 py-3 pb-0 bg-white shadow-2xl lg:flex lg:shadow-none droppy lg:p-0 lg:relative lg:z-auto lg:items-center lg:w-auto lg:border-none"},[_c('div',{staticClass:"flex flex-col lg:flex-row lg:flex-grow lg:space-y-0"},_vm._l((_vm.regionMenu),function(item,i){return _c('nav-item',{key:i,style:({ order: item.order }),attrs:{"item":item},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}})}),1),_c('div',{staticClass:"grid grid-cols-2 gap-4 my-3 text-center lg:w-auto lg:mb-0 lg:my-0"},[_c('button',{staticClass:"px-2 btn-blue",on:{"click":function($event){return _vm.showLogin()}}},[_vm._v(" Login ")]),_c('router-link',{staticClass:"self-center px-2 text-white bg-pink-400 btn-blue",class:{
                            'btn-disabled': this.$route.name == 'NewOwner'
                        },attrs:{"to":{
                            name: 'Sign-up',
                            params: { region: _vm.getRegion() }
                        },"active-class":"btn-disabled"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}},[_vm._v("Create Account")])],1)])])],1),_c('div',{staticClass:"left-0 flex items-center justify-between w-full px-2 py-2 bg-white border-t lg:px-10",class:{ 'pt-2': _vm.phone }},[_c('a',{staticClass:"font-bold tracking-wide text-md hover:text-pink-500",attrs:{"href":'tel:' + _vm.phone.replaceAll(' ', '')}},[_c('i',{staticClass:"mr-2 fad fa-mobile-alt wiggle2"}),_vm._v(" "+_vm._s(_vm.phone)+" ")]),_c('span',{staticClass:"ml-2 mr-auto text-sm font-normal"},[_vm._v(_vm._s(_vm.$store.state[this.getRegion()].contact.openTime)+" - "+_vm._s(_vm.$store.state[this.getRegion()].contact.closeTime))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }