var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section-hero'),_c('section-cards'),_c('div',{staticClass:"relative bg-gray-900"},[_c('section-slider',{key:this.rand,staticClass:"transition-opacity duration-1000 gallery",class:{ 'opacity-0': this.disabled }})],1),_c('div',{staticClass:"py-32 bg-gray-100 section tile bg-opacity-30"},[_c('title-block',{staticClass:"text-center",scopedSlots:_vm._u([{key:"small",fn:function(){return [_vm._v("A home away from home")]},proxy:true},{key:"big",fn:function(){return [_vm._v("Dogs Love it Here")]},proxy:true}])}),_c('div',{staticClass:"container grid max-w-screen-lg grid-cols-1 px-2 mx-auto lg:gap-10 md:grid-cols-2 gap-y-10 gap-x-2"},[_c('div',{staticClass:"flex flex-col justify-between order-2 col md:order-1"},[_c('ul',{staticClass:"text-left list-disc list-outside"},_vm._l((_vm.features),function(item,i){return _c('li',{key:i,staticClass:"grid w-full mb-2"},[(
                                item.limit == _vm.getRegion() ||
                                item.limit == null
                            )?[_c('div',{staticClass:"flex flex-col items-center gap-3"},[_c('icon-stack',{attrs:{"icon":item.icon}}),_c('p',{staticClass:"self-center font-bold text-blue-700"},[_vm._v(" "+_vm._s(item.title)+" "),(item.limit)?_c('span',{staticClass:"font-normal text-blue-400 capitalize"},[_vm._v("- "+_vm._s(item.limit)+" only")]):_vm._e()])],1),_c('p',{staticClass:"text-sm text-center"},[_vm._v(" "+_vm._s(item.desc)+" "),(item.title == 'Grooming')?_c('router-link',{staticClass:"link-pink",attrs:{"to":{
                                        name: item.title,
                                        params: { region: item.limit },
                                    }}},[_vm._v("find out more")]):_vm._e(),(item.title == 'Pet Taxi')?_c('button',{staticClass:"link-pink",on:{"click":_vm.showTaxi}},[_vm._v(" find out more ")]):_vm._e()],1)]:_vm._e()],2)}),0)]),_c('div',{staticClass:"order-1 bg-cover rounded-sm rounded-lg shadow-xl md:order-2 photo1 aspect-w-6 aspect-h-6"})]),_c('div',{staticClass:"flex flex-col max-w-screen-lg px-2 mx-auto mt-10 text-center sm:flex-row sm:flex-wrap gap-x-2 gap-y-5"},[_c('router-link',{staticClass:"flex-grow text-sm btn-blue",attrs:{"to":{ name: 'Daycare', params: { region: _vm.getRegion() } }}},[_vm._v("Daycare Prices")]),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.getRegion() == 'brisbane'),expression:"getRegion() == 'brisbane'"}],staticClass:"flex-grow text-sm btn-blue",attrs:{"to":{ name: 'Explore', params: { region: _vm.getRegion() } }}},[_vm._v("Take a Tour")]),_c('router-link',{staticClass:"flex-grow text-sm btn-blue",attrs:{"to":{ name: 'Sign-up', params: { region: _vm.getRegion() } }}},[_vm._v("Create Account")])],1)],1),_c('section-reviews')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }