var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-gray-500 bg-gray-900"},[_c('div',{staticClass:"container max-w-screen-2xl px-3 py-10 mx-auto"},[_c('div',{staticClass:"flex flex-col space-y-5 sm:flex-row sm:space-y-0 sm:space-x-5"},[_c('div',{staticClass:"flex-grow font-bold text-center sm:text-left"},[_c('img',{staticClass:"mr-auto md:ml-0",attrs:{"src":require("../assets/img/gddc-text.svg"),"alt":"Gabba Doggy Daycare"}}),_c('ul',{staticClass:"grid w-full max-w-lg grid-cols-2 gap-4 mt-5 mb-10 text-sm text-center list-none sm:text-left"},[_c('li',{},[_c('router-link',{staticClass:"focus:outline-white hover:text-white",attrs:{"to":{ name: 'Vaccinations' }}},[_vm._v("Vaccinations")])],1),_c('li',{},[_c('router-link',{staticClass:"focus:outline-white hover:text-white",attrs:{"to":{ name: 'Privacy' }}},[_vm._v("Privacy Policy")])],1),_vm._m(0),_c('li',{},[_c('router-link',{staticClass:"focus:outline-white hover:text-white",attrs:{"to":{
                                name: 'Jobs',
                                params: { region: _vm.getRegion() },
                            }}},[_vm._v("Jobs / Volunteer")])],1),_c('li',{},[_c('router-link',{staticClass:"focus:outline-white hover:text-white",attrs:{"to":{
                                name: 'Contact',
                                params: { region: _vm.getRegion() },
                            }}},[_vm._v("Contact Us")])],1),_c('li',{},[_c('router-link',{staticClass:"focus:outline-white hover:text-white",attrs:{"to":{ name: 'Faq' }}},[_vm._v("Help")])],1)])]),_c('div',{staticClass:"self-end text-center sm:text-left"},[_c('div',{staticClass:"text-sm"},[_c('ul',{staticClass:"space-y-4 text-sm list-none md:space-y-1"},[_c('li',[_c('a',{staticClass:"text-lg font-bold text-gray-400 focus:outline-white hover:text-yellow-300",attrs:{"href":`tel:` +
                                    _vm.contact.phone.replaceAll(' ', '')}},[_c('i',{staticClass:"mr-2 fad fa-mobile-alt"}),_vm._v(_vm._s(_vm.contact.phone))])]),_c('li',[_c('a',{staticClass:"text-gray-400 break-all focus:outline-white hover:text-yellow-300",attrs:{"href":`mailto:` + _vm.contact.email}},[_vm._v(_vm._s(_vm.contact.email))])]),_c('li',[_vm._v(" "+_vm._s(_vm.contact.street)+", "+_vm._s(_vm.contact.suburb)+", "+_vm._s(_vm.contact.postcode)+" ")])])]),_c('div',{staticClass:"flex flex-row justify-center mt-4 space-x-3 sm:justify-start"},[_c('social-icon',{attrs:{"site":"facebook"}}),_c('social-icon',{attrs:{"site":"instagram"}}),_c('social-icon',{attrs:{"site":"tiktok"}})],1)])])]),_c('div',{staticClass:"py-1 text-center col bg-dark"},[_c('p',{staticClass:"text-xs tracking-wide"},[_vm._v(" © "),_c('router-link',{attrs:{"to":{ name: 'New Home', params: { region: _vm.getRegion() } }}},[_vm._v("gabbadoggydaycare.com")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{},[_c('a',{staticClass:"focus:outline-white hover:text-white",attrs:{"href":"/docs/GDDC-general-agreement.pdf","download":""}},[_vm._v("Daycare Contract")])])
}]

export { render, staticRenderFns }