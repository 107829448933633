<template>
    <div class="flex flex-col">
        <img
            src="https://res.cloudinary.com/dg5ybbkbh/image/upload/w_750,q_auto,f_auto/gddc/gddc_map.jpg"
            alt=""
            class="w-full h-auto"
        />
        <div class="w-full p-2">
            <p class="mb-2">Local Pickup and Dropoff</p>
            <div>
                <i class="fas fa-circle text-yellow-400 mr-2"></i
                ><span class="font-bold">Yellow - Zone One:</span> $10 each way
            </div>
            <div>
                <i class="fas fa-circle text-red-400 mr-2"></i
                ><span class="font-bold">Red - Zone Two:</span> $15 each way
            </div>
            <p class="mt-2">
                Please note: Our pet taxi service has limited availability, and
                may not always be available. To request a booking, just login to
                your account and click Request scheduled service.
            </p>
            <p class="mt-2">
                Due to traffic, number of bookings, and other factors, we're not
                able to guarantee pickup or dropoff at specific times and may
                arrive earlier or later than requested.
            </p>
            <p class="mt-2">Pet-taxi Hours of Operation:</p>

            <span class="mr-4">pick up:</span
            ><span class="text-right">7:00 AM - 9:30 AM</span> <br />
            <span class="mr-4">drop off:</span
            ><span class="text-right">3:00 PM - 5:00 PM</span>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style></style>
