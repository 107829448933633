<template>
  <div class="rounded-xl shadow-xl p-3 pattern">
    <div class="bg-white h-full w-full rounded-sm shadow-md flex flex-col">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style>

</style>